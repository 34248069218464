import React from 'react'

import tileimage6 from '../assets/images/whoweare/Corprate_WhoWeAre_SLT_06.jpg'
import tileimage9 from '../assets/images/whoweare/MMARKEE.jpg'
import tileimage14 from '../assets/images/whoweare/Corprate_WhoWeAre_SLT_014.jpg'
import tileimage145 from '../assets/images/whoweare/jared_bw_web.jpg'
import tileimage1456 from '../assets/images/whoweare/NANCY_BW1.jpg'
import tileimage145678 from '../assets/images/whoweare/SHEAMUS_TOAL_HEADSHOT.jpg'
import tileimage14561 from '../assets/images/whoweare/beth.jpg'

const MBSeniorLeadership = () => (
  <div
    class="secondgroup text-left accordion"
    id="mbseniorleadershipcolapsegroup"
  >
    <div>
      <a
        class="row btn p-0 mb-1 d-flex collapsed"
        data-toggle="collapse"
        href="#collapseExample119"
        role="button"
        aria-expanded="false"
        aria-controls="collapseExample11"
      >
        <div class="col-12">
          <hr class="border-top " />
        </div>
        <div class="col-4 fill">
          <img src={tileimage145678} className="img-fluid mb-2" alt="" />
        </div>

        <div class="col-8 text-left">
          <h5>SHEAMUS TOAL</h5>
          <p>
            Chief Operating Officer &amp;
            <br />
            Chief Financial Officer
          </p>
        </div>
      </a>
      <div
        class="row mt-4 collapse close-button"
        id="collapseExample119"
        data-toggle="collapse"
        data-target="#collapseExample119"
        aria-expanded="false"
        aria-controls=""
        data-parent="#mbseniorleadershipcolapsegroup"
      >
        <div class="col-12">
          <p className="text-left">
            Sheamus joined The Children’s Place in 2022 as Senior Vice President
            &amp; Chief Financial Officer. His strong appreciation for the
            Company’s digital transformation and his prior executive-level
            retail operating experience quickly led to his expanded role as
            Chief Operating Officer and Chief Financial Officer in 2023. Sheamus
            has oversight for IT, Logistics, Distribution, Store Operations,
            Finance, Accounting, Investor Relations, Real Estate, and Legal.
            Prior to joining The Children’s Place, Sheamus held the role of
            Executive Vice President and Chief Financial Officer at Saatva, a
            high-growth digital e-commerce retailer. Prior to Saatva, he spent
            nearly 17 years with New York and Company where he held several
            senior level finance and operational positions as Chief Operating
            Officer, Chief Financial Officer, Chief Accounting Officer and
            Treasurer, culminating in his role as Chief Executive Officer from
            2020 to 2021. Sheamus graduated Magna Cum Laude with a Bachelor of
            Science in Accounting from St. John’s University and is a Certified
            Public Accountant.
          </p>
        </div>
      </div>
    </div>

    <div>
      <a
        class="row btn p-0 mb-1 d-flex collapsed"
        data-toggle="collapse"
        href="#collapseExample5"
        role="button"
        aria-expanded="false"
        aria-controls="collapseExample5"
      >
        <div class="col-12">
          <hr class="border-top " />
        </div>
        <div class="col-4 fill">
          <img src={tileimage6} className="img-fluid mb-2" alt="" />
        </div>

        <div class="col-8 text-left">
          <h5>JENNIFER GROVES</h5>
          <p>
            Senior Vice President,
            <br /> Design
          </p>
        </div>
      </a>
      <div
        class="row mt-4 collapse close-button"
        id="collapseExample5"
        data-toggle="collapse"
        data-target="#collapseExample3"
        aria-expanded="false"
        aria-controls=""
        data-parent="#mbseniorleadershipcolapsegroup"
      >
        <div class="col-12">
          <p className="text-left">
            Jennifer Groves joined The Children's Place in 2014. She leads
            global product design for our brands. Her 25 years of creative
            design experience determines the brand’s seasonal color, concept,
            and overall aesthetic. Prior to joining The Children’s Place, she
            held Senior Vice President roles at Aeropostale and Gap Kids &amp;
            Baby. She also held design roles of increasing responsibility at
            Abercrombie &amp; Fitch, Guess and Bebe Stores. Jennifer earned a
            Bachelor of Fine Arts in Fashion Design from Parsons School of
            Design.
          </p>
        </div>
      </div>
    </div>

    <div>
      <a
        class="row btn p-0 mb-1 mb-1 d-flex d-flex collapsed"
        data-toggle="collapse"
        href="#collapseExample13"
        role="button"
        aria-expanded="false"
        aria-controls="collapseExample13"
      >
        <div class="col-12">
          <hr class="border-top " />
        </div>
        <div class="col-4 fill">
          <img src={tileimage14} className="img-fluid mb-2" alt="" />
        </div>
        <div class="col-8 text-left">
          <h5>RAJAT JAIN</h5>
          <p>Chief Technology, Logistics &amp; Stores Officer</p>
        </div>
      </a>
      <div
        class="row mt-4 collapse close-button"
        id="collapseExample13"
        data-toggle="collapse"
        data-target="#collapseExample3"
        aria-expanded="false"
        aria-controls=""
        data-parent="#mbseniorleadershipcolapsegroup"
      >
        <div class="col-12">
          <p className="text-left">
            Rajat joined The Children's Place in 2017 as Head of Digital and
            Store Technology. In 2019 he was promoted to lead all of IT and is
            responsible for leading enterprise transformation including defining
            and implementing a multi-year technology strategy, maximizing
            ecommerce and omnichannel capabilities, managing information
            security and IT risk, and providing business support services. Rajat
            has since assumed an expanded leadership role with oversight for
            Global Logistics &amp; Distribution and Stores in addition to IT.
            Rajat brings a wealth of experience across multiple retail
            organizations gained in his prior position as the Retail Technology
            Leader at SapientRazorfish. Prior to that he worked in information
            security at SafeNet Inc. and telecommunications at Hughes Software
            Systems. Rajat holds a Bachelor’s degree in Information Technology
            from Delhi University and a Masters of Business Administration in
            Information Systems from the Institute of Management Technology.
          </p>
        </div>
      </div>
    </div>

    <div>
      <a
        class="row btn p-0 mb-1 d-flex collapsed"
        data-toggle="collapse"
        href="#collapseExample111"
        role="button"
        aria-expanded="false"
        aria-controls="collapseExample111"
      >
        <div class="col-12">
          <hr class="border-top " />
        </div>
        <div class="col-4 fill">
          <img src={tileimage14561} className="img-fluid mb-2" alt="" />
        </div>

        <div class="col-8 text-left">
          <h5>MARY BETH SHERIDAN</h5>
          <p>Chief Merchandising Officer</p>
        </div>
      </a>
      <div
        class="row mt-4 collapse close-button"
        id="collapseExample111"
        data-toggle="collapse"
        data-target="#collapseExample111"
        aria-expanded="false"
        aria-controls=""
        data-parent="#mbseniorleadershipcolapsegroup"
      >
        <div class="col-12">
          <p className="text-left">
            Mary Beth joined The Children’s Place in 2023 as Chief Merchandising
            Officer. In her role, Mary Beth has direct leadership responsibility
            for Merchandising, Global Sourcing, Planning and Allocation and is
            instrumental in identifying and driving growth opportunities on both
            the top and bottom lines through collaborative, cross-functional
            strategies. Prior to joining the Company, Mary Beth was most
            recently Chief Merchandising Officer at Anthropologie from 2020 to
            2023. She also held other senior executive roles at Macy’s and Lord
            &amp; Taylor. Mary Beth graduated Magna Cum Laude with a Bachelor of
            Science in Business Administration from the Boston College Carroll
            School of Management.
          </p>
        </div>
      </div>
    </div>

    <div>
      <a
        class="row btn p-0 mb-1 d-flex collapsed"
        data-toggle="collapse"
        href="#collapseExample77"
        role="button"
        aria-expanded="false"
        aria-controls="collapseExample77"
      >
        <div class="col-12">
          <hr class="border-top " />
        </div>
        <div class="col-4 fill">
          <img src={tileimage145} className="img-fluid mb-2" alt="" />
        </div>

        <div class="col-8 text-left">
          <h5>JARED SHURE</h5>
          <p>
            Senior Vice President,
            <br /> General Counsel
          </p>
        </div>
      </a>
      <div
        class="row mt-4 collapse close-button"
        id="collapseExample77"
        data-toggle="collapse"
        data-target="#collapseExample77"
        aria-expanded="false"
        aria-controls=""
        data-parent="#mbseniorleadershipcolapsegroup"
      >
        <div class="col-12">
          <p className="text-left">
            Jared Shure joined The Children’s Place in 2018 as Vice President,
            Assistant General Counsel and has assumed roles of increasing
            responsibility culminating in his appointment to the role of Senior
            Vice President, General Counsel in 2021. In this role, Jared leads
            all areas of the Legal team including International Trade, Product
            Safety &amp; Intellectual Property, Contracts Compliance &amp;
            International Operations, Real Estate, Litigation, Employment Law,
            and Board Governance and Administration and oversees Environment
            &amp; Social Responsibility and Enterprise Risk. Jared attained his
            Bachelor of Science in Business Administration from the University
            of North Carolina at Chapel Hill and his Juris Doctorate from
            Cornell Law School.
          </p>
        </div>
      </div>
    </div>
  </div>
)

export default MBSeniorLeadership
