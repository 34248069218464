import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'

const SecondaryNav = ({ active,name1,link1,name2,link2,name3,link3,name4,link4,name5,link5 }) => (
    <div class="secondary-nav">
        <div class="row mb-3 text-center d-none d-md-block d-lg-block">
            <div class="col-12">
                <ul>
                    <li><Link to={link1}>
                            <button type="button" className={"btn btn-primary " + (active === name1 ? 'active' : '')}>{name1}</button>
                    </Link></li>
                    <li><Link to={link2}>
                            <button type="button" className={"btn btn-primary " + (active === name2 ? 'active' : '')}>{name2}</button>
                    </Link></li>
                    {name3 && <li><Link to={link3}>
                        <button type="button" className={"btn btn-primary " + (active === name3 ? 'active' : '')}>{name3}</button>
                    </Link></li>}
                    {name4 && <li><Link to={link4}>
                        <button type="button" className={"btn btn-primary " + (active === name4 ? 'active' : '')}>{name4}</button>
                    </Link></li>}
                    {name5 && <li><Link to={link5}>
                        <button type="button" className={"btn btn-primary " + (active === name5 ? 'active' : '')}>{name5}</button>
                    </Link></li>}
                </ul>
            </div>
        </div>
        <div class="row mb-3 text-center d-md-none d-lg-none">
            <div class="col-12">
                <div class="dropdown">
                    <button class="btn btn-secondary dropdown-toggle btn-block" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        {active}
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <Link className={"dropdown-item " + (active === name1 ? 'd-none' : '')} to={link1}>{name1}</Link>
                        <Link className={"dropdown-item " + (active === name2 ? 'd-none' : '')} to={link2}>{name2}</Link>
                        {name3 &&<Link className={"dropdown-item " + (active === name3 ? 'd-none' : '')} to={link3}>{name3}</Link>}
                        {name4 &&<Link className={"dropdown-item " + (active === name4 ? 'd-none' : '')} to={link4}>{name4}</Link>}
                        {name5 &&<Link className={"dropdown-item " + (active === name5 ? 'd-none' : '')} to={link5}>{name5}</Link>}
                    </div>
                </div>
            </div>
        </div>
    </div>
)

SecondaryNav.propTypes = {
    active: PropTypes.string.isRequired,
    name1: PropTypes.string.isRequired,
    link1: PropTypes.string.isRequired,
    name2: PropTypes.string.isRequired,
    link2: PropTypes.string.isRequired,
    name3: PropTypes.string,
    link3: PropTypes.string,
    name4: PropTypes.string,
    link4: PropTypes.string,
    name5: PropTypes.string,
    link5: PropTypes.string,
}

export default SecondaryNav
