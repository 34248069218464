import React from 'react'

import Layout from '../layouts/layout'
import SEO from '../components/seo'
import TitleHeader from '../components/titleheader'
import SecondaryNav from '../components/secondarynav'
import BorderedBox from '../components/borderedbox'
import MBSeniorLeadership from '../components/mbsenior-leadership'

import headerimage from '../assets/images/whoweare/Corprate_WhoWeAre_BoardofDirectors-A_03.gif'
import mbheaderimage from '../assets/images/whoweare/Corprate_WhoWeAre_BoardofDirectors-MB-A.gif'
import tileimage1 from '../assets/images/whoweare/Muhammad_Umair.jpg'
import tileimage6 from '../assets/images/whoweare/Corprate_WhoWeAre_SLT_06.jpg'
import tileimage9 from '../assets/images/whoweare/MMARKEE.jpg'
import tileimage14 from '../assets/images/whoweare/Corprate_WhoWeAre_SLT_014.jpg'
import tileimage145 from '../assets/images/whoweare/jared_bw_web.jpg'
import tileimage1456 from '../assets/images/whoweare/NANCY_BW1.jpg'

import tileimage145678 from '../assets/images/whoweare/SHEAMUS_TOAL_HEADSHOT.jpg'
import tileimage14561 from '../assets/images/whoweare/beth.jpg'

import { trackPageData, getPageDetails } from '../analytics'

class SeniorLeadership extends React.PureComponent {
  constructor(props) {
    super(props)

    let dataManager = {
      pageDetails: {},
    }
    if (typeof window !== `undefined`) {
      if (window.dataManager) {
        dataManager = window.dataManager
      }
    }
    dataManager.pageDetails = getPageDetails()
  }

  componentDidMount() {
    trackPageData()
  }

  render() {
    return (
      <Layout>
        <SEO
          title="Senior Leadership | The Children's Place Corporate Website"
          description="Learn more about The Children's Place Senior Leadership team on The Children's Place Corporate Website."
        />

        {/* Page Title & Header Image */}
        <div class="row mb-3">
          <div class="col-12">
            <TitleHeader
              image={headerimage}
              mbheaderimage={mbheaderimage}
              title="WHO WE ARE"
            />
          </div>
        </div>
        {/* End Page Title & Header Image */}

        <SecondaryNav
          active="Senior Leadership"
          name1="About Us"
          link1="/about-us"
          name2="Senior Leadership"
          link2="/senior-leadership"
          name3="Board of Directors"
          link3="/board-of-directors"
          name4="International Franchises"
          link4="/international-franchises"
        />
        <div class="row mb-5 d-none d-md-flex">
          <div class="col-3 fill">
            <img src={tileimage1} className="img-fluid" alt="" />
          </div>

          <div class="col-9">
            <h4>
              MUHAMMAD UMAIR{' '}
              <span class="text-muted base-font dark">
                | President &amp; Interim Chief Executive Officer
              </span>
            </h4>
            <p>
              Mr. Umair serves as the President and Interim Chief Executive
              Officer of the Company. Prior to his appointment as President and
              Interim Chief Executive Officer, Mr. Umair was a Senior Advisor
              for Origin Funding Partners, a global trade finance fund, where he
              was responsible for credit, recovery, and due diligence. Prior to
              joining Origin Funding Partners, Mr. Umair held positions in
              various organizations, including Head of Advisory at Armacom and
              Senior Auditor at Ernst & Young. He is a Chartered Accountant and
              associate member of the Institute of Chartered Accountants of
              Pakistan and the Institute of Chartered Accountants in England and
              Wales. Mr. Umair has more than 17 years of financial and
              commercial experience, including investment management, corporate
              advisory, operational and financial due diligence and audit.
            </p>
          </div>
        </div>

        <div class="row d-md-none">
          {/* <div class="col-12">
            <hr class="border-top pb-1" />
          </div> */}
          <div class="col-4 fill">
            <img src={tileimage1} className="img-fluid" alt="" />
          </div>

          <div class="col-8">
            <h4 class="mb-1 mt-4">MUHAMMAD UMAIR</h4>
            <p class="text-muted base-font dark text-large">
              President &amp;
              <br /> Interim Chief Executive Officer
            </p>
          </div>
          <div class="col-12 mt-5">
            <p class=" pb-4 border-bottom">
              Mr. Umair serves as the President and Interim Chief Executive
              Officer of the Company. Prior to his appointment as President and
              Interim Chief Executive Officer, Mr. Umair was a Senior Advisor
              for Origin Funding Partners, a global trade finance fund, where he
              was responsible for credit, recovery, and due diligence. Prior to
              joining Origin Funding Partners, Mr. Umair held positions in
              various organizations, including Head of Advisory at Armacom and
              Senior Auditor at Ernst &amp; Young. He is a Chartered Accountant
              and associate member of the Institute of Chartered Accountants of
              Pakistan and the Institute of Chartered Accountants in England and
              Wales. Mr. Umair has more than 17 years of financial and
              commercial experience, including investment management, corporate
              advisory, operational and financial due diligence and audit.
            </p>
          </div>
        </div>

        <div
          class="firstgroup d-none d-md-block"
          id="seniorleadershipcolapsegroup"
        >
          {/* START Content for text Section1 */}
          <div class="row mb-3 text-center">
            <div class="col-lg-3 col-md-6 col-sm-12">
              <a
                class="btn p-0 "
                data-toggle="collapse"
                href="#collapseExample119"
                role="button"
                aria-expanded="false"
                aria-controls="collapseExample119"
              >
                <img src={tileimage145678} className="img-fluid mb-2" alt="" />
                <h5>SHEAMUS TOAL</h5>
                <p>
                  Chief Operating Officer &amp;
                  <br />
                  Chief Financial Officer
                </p>
              </a>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-12">
              <a
                class="btn p-0 "
                data-toggle="collapse"
                href="#collapseExample5"
                role="button"
                aria-expanded="true"
                aria-controls="collapseExample5"
              >
                <img src={tileimage6} className="img-fluid mb-2" alt="" />
                <h5>JENNIFER GROVES</h5>
                <p>
                  Senior Vice President,
                  <br /> Design
                </p>
              </a>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-12">
              <a
                class="btn p-0 "
                data-toggle="collapse"
                href="#collapseExample13"
                role="button"
                aria-expanded="false"
                aria-controls="collapseExample13"
              >
                <img src={tileimage14} className="img-fluid mb-2" alt="" />
                <h5 class="pl-1 pr-1">RAJAT JAIN</h5>
                <p class="pl-1 pr-1">
                  Chief Technology, Logistics &amp; Stores Officer
                </p>
              </a>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12">
              <a
                class="btn p-0 "
                data-toggle="collapse"
                href="#collapseExample111"
                role="button"
                aria-expanded="false"
                aria-controls="collapseExample111"
              >
                <img src={tileimage14561} className="img-fluid mb-2" alt="" />
                <h5>MARY BETH SHERIDAN</h5>
                <p>Chief Merchandising Officer</p>
              </a>
            </div>
          </div>
          {/* END Content for text Section1 */}

          {/* START Hidden content for dropdown text Section1 */}
          <div class="row mb-5">
            <div class="col-lg-12 col-md-12 col-sm-12">
              <div
                class="collapse"
                id="collapseExample5"
                data-parent="#seniorleadershipcolapsegroup"
              >
                <div class="">
                  <BorderedBox>
                    <button
                      class="btn close-button"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseExample5"
                      aria-expanded="false"
                      aria-controls=""
                    >
                      X
                    </button>
                    <h5 className="text-left">
                      JENNIFER GROVES
                      <span class="text-muted base-font dark">
                        {' '}
                        | Senior Vice President, Design
                      </span>
                    </h5>

                    <p className="text-left">
                      Jennifer Groves joined The Children's Place in 2014. She
                      leads global product design for our brands. Her 25 years
                      of creative design experience determines the brand’s
                      seasonal color, concept, and overall aesthetic. Prior to
                      joining The Children’s Place, she held Senior Vice
                      President roles at Aeropostale and Gap Kids &amp; Baby.
                      She also held design roles of increasing responsibility at
                      Abercrombie &amp; Fitch, Guess and Bebe Stores. Jennifer
                      earned a Bachelor of Fine Arts in Fashion Design from
                      Parsons School of Design.
                    </p>
                  </BorderedBox>
                </div>
              </div>

              <div
                class="collapse"
                id="collapseExample13"
                data-parent="#seniorleadershipcolapsegroup"
              >
                <div class="">
                  <BorderedBox>
                    <button
                      class="btn close-button"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseExample13"
                      aria-expanded="false"
                      aria-controls=""
                    >
                      X
                    </button>
                    <h5 className="text-left">
                      RAJAT JAIN{' '}
                      <span class="text-muted base-font dark">
                        {' '}
                        | Chief Technology, Logistics &amp; Stores Officer{' '}
                      </span>
                    </h5>
                    <p className="text-left">
                      Rajat joined The Children's Place in 2017 as Head of
                      Digital and Store Technology. In 2019 he was promoted to
                      lead all of IT and is responsible for leading enterprise
                      transformation including defining and implementing a
                      multi-year technology strategy, maximizing ecommerce and
                      omnichannel capabilities, managing information security
                      and IT risk, and providing business support services.
                      Rajat has since assumed an expanded leadership role with
                      oversight for Global Logistics &amp; Distribution and
                      Stores in addition to IT. Rajat brings a wealth of
                      experience across multiple retail organizations gained in
                      his prior position as the Retail Technology Leader at
                      SapientRazorfish. Prior to that he worked in information
                      security at SafeNet Inc. and telecommunications at Hughes
                      Software Systems. Rajat holds a Bachelor’s degree in
                      Information Technology from Delhi University and a Masters
                      of Business Administration in Information Systems from the
                      Institute of Management Technology.
                    </p>
                  </BorderedBox>
                </div>
              </div>

              <div
                class="collapse"
                id="collapseExample111"
                data-parent="#seniorleadershipcolapsegroup"
              >
                <div class="">
                  <BorderedBox>
                    <button
                      class="btn close-button"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseExample111"
                      aria-expanded="false"
                      aria-controls=""
                    >
                      X
                    </button>
                    <h5 className="text-left">
                      MARY BETH SHERIDAN
                      <span class="text-muted base-font dark">
                        {' '}
                        | Chief Merchandising Officer
                      </span>
                    </h5>
                    <p className="text-left">
                      Mary Beth joined The Children’s Place in 2023 as Chief
                      Merchandising Officer. In her role, Mary Beth has direct
                      leadership responsibility for Merchandising, Global
                      Sourcing, Planning and Allocation and is instrumental in
                      identifying and driving growth opportunities on both the
                      top and bottom lines through collaborative,
                      cross-functional strategies. Prior to joining the Company,
                      Mary Beth was most recently Chief Merchandising Officer at
                      Anthropologie from 2020 to 2023. She also held other
                      senior executive roles at Macy’s and Lord &amp; Taylor.
                      Mary Beth graduated Magna Cum Laude with a Bachelor of
                      Science in Business Administration from the Boston College
                      Carroll School of Management.
                    </p>
                  </BorderedBox>
                </div>
              </div>

              <div
                class="collapse"
                id="collapseExample119"
                data-parent="#seniorleadershipcolapsegroup"
              >
                <div class="">
                  <BorderedBox>
                    <button
                      class="btn close-button"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseExample119"
                      aria-expanded="false"
                      aria-controls=""
                    >
                      X
                    </button>
                    <h5 className="text-left">
                      SHEAMUS TOAL
                      <span class="text-muted base-font dark">
                        {' '}
                        | Chief Operating Officer &amp; Chief Financial Officer
                      </span>
                    </h5>
                    <p className="text-left">
                      Sheamus joined The Children’s Place in 2022 as Senior Vice
                      President &amp; Chief Financial Officer. His strong
                      appreciation for the Company’s digital transformation and
                      his prior executive-level retail operating experience
                      quickly led to his expanded role as Chief Operating
                      Officer and Chief Financial Officer in 2023. Sheamus has
                      oversight for IT, Logistics, Distribution, Store
                      Operations, Finance, Accounting, Investor Relations, Real
                      Estate, and Legal. Prior to joining The Children’s Place,
                      Sheamus held the role of Executive Vice President and
                      Chief Financial Officer at Saatva, a high-growth digital
                      e-commerce retailer. Prior to Saatva, he spent nearly 17
                      years with New York and Company where he held several
                      senior level finance and operational positions as Chief
                      Operating Officer, Chief Financial Officer, Chief
                      Accounting Officer and Treasurer, culminating in his role
                      as Chief Executive Officer from 2020 to 2021. Sheamus
                      graduated Magna Cum Laude with a Bachelor of Science in
                      Accounting from St. John’s University and is a Certified
                      Public Accountant.
                    </p>
                  </BorderedBox>
                </div>
              </div>
            </div>
          </div>
          {/* END Hidden content for dropdown text Section1 */}

          <div class="row mb-3 text-center">
            <div class="col-lg-3 col-md-6 col-sm-12">
              <a
                class="btn p-0 "
                data-toggle="collapse"
                href="#collapseExample77"
                role="button"
                aria-expanded="false"
                aria-controls="collapseExample77"
              >
                <img src={tileimage145} className="img-fluid mb-2" alt="" />
                <h5>JARED SHURE</h5>
                <p>
                  Senior Vice President,
                  <br /> General Counsel
                </p>
              </a>
            </div>
          </div>

          {/* START Hidden content for dropdown text Section2 */}
          <div class="row mb-5">
            <div class="col-lg-12 col-md-12 col-sm-12">
              <div
                class="collapse"
                id="collapseExample77"
                data-parent="#seniorleadershipcolapsegroup"
              >
                <div class="">
                  <BorderedBox>
                    <button
                      class="btn close-button"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseExample77"
                      aria-expanded="false"
                      aria-controls=""
                    >
                      X
                    </button>
                    <h5 className="text-left">
                      JARED SHURE
                      <span class="text-muted base-font dark">
                        {' '}
                        | Senior Vice President, General Counsel
                      </span>
                    </h5>
                    <p className="text-left">
                      Jared Shure joined The Children's Place in 2018 as Vice
                      President, Assistant General Counsel and has assumed roles
                      of increasing responsibility culminating in his
                      appointment to the role of Senior Vice President, General
                      Counsel in 2021. In this role, Jared leads all areas of
                      the Legal team including International Trade, Product
                      Safety & Intellectual Property, Contracts Compliance &
                      International Operations, Real Estate, Litigation,
                      Employment Law, and Board Governance and Administration
                      and oversees Environment & Social Responsibility and
                      Enterprise Risk. Jared attained his Bachelor of Science in
                      Business Administration from the University of North
                      Carolina at Chapel Hill and his Juris Doctorate from
                      Cornell Law School.
                    </p>
                  </BorderedBox>
                </div>
              </div>
            </div>
          </div>
          {/* END Hidden content for dropdown text Section2 */}

          {/* START Content for text Section3 */}
          <div class="row mb-3 text-center"></div>
          {/* END Content for text Section3 */}

          {/* START Hidden content for dropdown text Section3 */}
          <div class="row mb-5">
            <div class="col-lg-12 col-md-12 col-sm-12"></div>
          </div>

          {/* END Hidden content for dropdown text Section3 */}
        </div>
        {/* END Hidden content for dropdown text Section4 */}
        <div class="d-md-none">
          <MBSeniorLeadership />
        </div>
      </Layout>
    )
  }
}

export default SeniorLeadership
