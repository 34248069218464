import React from 'react'
import PropTypes from 'prop-types'


const BorderedBox = ({ children, classname }) => (
    <div class={"background-box "+classname}>
      <div class="white-box">
        <div class="box-body">
          {children}
        </div>
      </div>
    </div>
)



BorderedBox.propTypes = {
  classname: PropTypes.string,
  children: PropTypes.node.isRequired,
}


export default BorderedBox
